<template>
  <vue-modal
    :modal-title="$t('pages.profile.tabs.personal_data.personal')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :modalLarge="true"
    :loading-primary-footer-button="isLoading"
    :showSecondaryFooterButton="false"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="profile">
        <article>
          <div class="alert alert-danger" role="alert" v-if="error">
            {{ error }}
          </div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <employee-social-security-field
                  id="edit-employee-social-security"
                  name="socialSecurityNo"
                  :form="form"
                  v-if="canEditCass"
                />

                <employee-no-authorization-residente-field
                  id="edit-employee-no-authorization-residente"
                  name="noAutorizacionResidente"
                  :form="form"
                  v-if="canEditResidence"
                />

                <employee-cass-expiration-date-field
                  id="edit-employee-cass-expiration-date"
                  name="cassExpirationDate"
                  :form="form"
                  v-if="canEditCassExpiration"
                />
              </div>
              <div class="col-md-6">
                <employee-dni-number-field
                  id="edit-employee-dni-number"
                  name="dniNumber"
                  :form="form"
                  v-if="canEditPassport"
                />

                <employee-dni-expiration-date-field
                  id="edit-employee-dni-expiration-date"
                  name="dniExpirationDate"
                  :form="form"
                  v-if="canEditPassportExp"
                />

                <employee-birth-day-field
                  id="edit-employee-birth-day"
                  name="birthDate"
                  :form="form"
                  v-if="canEditBornDate"
                />
              </div>
            </div>
          </form>
        </article>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import { SHOW_PERSONAL_EDIT_FORM_EVENT, PROFILE_DATA_UPDATED_EVENT } from "./index";

import {
  EmployeeSocialSecurityField,
  EmployeeNoAuthorizationResidenteField,
  EmployeeDniNumberField,
  EmployeeDniExpirationDateField,
  EmployeeBirthDayField,
  EmployeeCassExpirationDateField,
} from "./fields";
import { Form } from "@/components/form";

import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

import api from "@/api/user";
import { RequiredRule } from "@/components/form/data/rules";

export default {
  components: {
    VueModal,
    EmployeeSocialSecurityField,
    EmployeeNoAuthorizationResidenteField,
    EmployeeDniNumberField,
    EmployeeDniExpirationDateField,
    EmployeeBirthDayField,
    EmployeeCassExpirationDateField,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      postedNumber: "",
      options: {
        penColor: "#c0f",
      },
      userData: {},
      error: null,
      form: new Form({
        data: {
          key: null,
          socialSecurityNo: null,
          noAutorizacionResidente: null,
          cassExpirationDate: null,
          dniNumber: null,
          dniExpirationDate: null,
          birthDate: null,
          birthDateSpecified: this.canEditBornDate,
          cassExpirationDateSpecified: this.canEditPassportExp,
          dniExpirationDateSpecified: this.canEditPassportExp,
        },
      }),
    };
  },
  computed: {
    ...mapGetters("auth", [
      "canEditCass",
      "canEditResidence",
      "canEditCassExpiration",
      "canEditPassport",
      "canEditPassportExp",
      "canEditNationality",
      "canEditBornDate",
    ]),
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    fieldsRules() {
      let rules = [];

      if (this.canEditCass) {
        rules.push(
          new RequiredRule({
            name: "socialSecurityNo",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.canEditResidence) {
        rules.push(
          new RequiredRule({
            name: "noAutorizacionResidente",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.canEditCassExpiration) {
        rules.push(
          new RequiredRule({
            name: "cassExpirationDate",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.canEditPassport) {
        rules.push(
          new RequiredRule({
            name: "dniNumber",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.canEditPassportExp) {
        rules.push(
          new RequiredRule({
            name: "dniExpirationDate",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      return rules;
    },
  },
  created() {
    EventBus.listen(SHOW_PERSONAL_EDIT_FORM_EVENT, ({ data }) => {
      this.clear();
      this.form.fill(data);
      if (this.$isset(this.form.birthDate)) {
        this.form.birthDate = this.$parseDate(this.form.birthDate);
      }
      if (this.$isset(this.form.cassExpirationDate)) {
        this.form.cassExpirationDate = this.$parseDate(this.form.cassExpirationDate);
      }
      if (this.$isset(this.form.dniExpirationDate)) {
        this.form.dniExpirationDate = this.$parseDate(this.form.dniExpirationDate);
      }
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off([SHOW_PERSONAL_EDIT_FORM_EVENT]);
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);
      if (this.form.validate()) {
        try {
          this.isLoading = true;

          await api.myProfilePersonalUpdate(this.form.data());
          EventBus.fire(PROFILE_DATA_UPDATED_EVENT);
          this.$toasts.success(this.$t("components.messages.success"));

          this.showModal = false;
        } catch (error) {
          Sentry.captureException(error);
          this.error = this.$t("components.messages.error");
          console.error(error);
        }
        this.isLoading = false;
      }
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.showModal = false;
    },
    clear() {
      this.error = null;
    },
  },
};
</script>
