<template>
  <vue-modal
    :modal-title="$t('pages.profile.tabs.personal_data.contact_data')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :modalLarge="true"
    :loading-primary-footer-button="isLoading"
    :showSecondaryFooterButton="false"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="profile">
        <article>
          <div class="alert alert-danger" role="alert" v-if="error">
            {{ error }}
          </div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <employee-address-field
                  id="edit-employee-address"
                  name="address"
                  :form="form"
                  v-if="canEditAddress"
                />

                <employee-address-2-field
                  id="edit-employee-address2"
                  name="address2"
                  :form="form"
                  v-if="canEditAddress2"
                />
              </div>
              <div class="col-md-6">
                <employee-private-phone-field
                  id="edit-employee-private-phone"
                  name="mobilePhoneNo"
                  :form="form"
                  v-if="canEditPersonalPhone"
                />

                <employee-extension-field
                  id="edit-employee-extension"
                  name="extension"
                  :form="form"
                  v-if="canEditPhoneExt"
                />

                <employee-private-email-field
                  id="edit-employee-private-email"
                  name="email"
                  :form="form"
                  v-if="canEditPersonalEmail"
                />
              </div>
            </div>
          </form>
        </article>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import { SHOW_DIRECTION_CONTACT_EDIT_FORM_EVENT, PROFILE_DATA_UPDATED_EVENT } from "./index";

import {
  EmployeeAddressField,
  EmployeeAddress2Field,
  EmployeeExtensionField,
  EmployeePrivateEmailField,
  EmployeePrivatePhoneField,
} from "./fields";
import { Form } from "@/components/form";

import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

import api from "@/api/user";
import { EmailRule } from "@/components/form/data/rules";

export default {
  components: {
    VueModal,
    EmployeeAddressField,
    EmployeeAddress2Field,
    EmployeeExtensionField,
    EmployeePrivateEmailField,
    EmployeePrivatePhoneField,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      postedNumber: "",
      options: {
        penColor: "#c0f",
      },
      userData: {},
      error: null,
      form: new Form({
        data: {
          key: null,
          address: null,
          address2: null,
          mobilePhoneNo: null,
          extension: null,
          email: null,
        },
      }),
    };
  },
  computed: {
    ...mapGetters("auth", [
      "canEditAddress",
      "canEditAddress2",
      "canEditCodePostal",
      "canEditCity",
      "canEditCountry",
      "canEditPersonalPhone",
      "canEditPhoneExt",
      "canEditDirectPhone",
      "canEditPersonalEmail",
    ]),
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    fieldsRules() {
      let rules = [];

      if (this.canEditPersonalEmail) {
        rules.push(
          new EmailRule({
            name: "email",
            errorMessage: this.$t("components.fields.rules.emailWrong"),
            optional: true,
          })
        );
      }

      return rules;
    },
  },
  created() {
    EventBus.listen(SHOW_DIRECTION_CONTACT_EDIT_FORM_EVENT, ({ data }) => {
      this.clear();
      this.form.fill(data);
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off([SHOW_DIRECTION_CONTACT_EDIT_FORM_EVENT]);
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);
      if (this.form.validate()) {
        try {
          this.isLoading = true;

          await api.myProfileDirectionAndContactUpdate(this.form.data());
          EventBus.fire(PROFILE_DATA_UPDATED_EVENT);

          this.$toasts.success(this.$t("components.messages.success"));

          this.showModal = false;
        } catch (error) {
          Sentry.captureException(error);
          this.error = this.$t("components.messages.error");
          console.error(error);
        }
        this.isLoading = false;
      }
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.showModal = false;
    },
    clear() {
      this.error = null;
    },
  },
};
</script>
