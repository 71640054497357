<template>
  <vue-modal
    :modal-title="$t('pages.profile.tabs.personal_data.personal')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :modalLarge="true"
    :loading-primary-footer-button="isLoading"
    :showSecondaryFooterButton="false"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="profile">
        <article>
          <div class="alert alert-danger" role="alert" v-if="error">
            {{ error }}
          </div>
          <form>
            <div class="row">
              <div class="col">
                <employee-iban-field
                  :required="true"
                  id="edit-employee-iban"
                  name="iban"
                  :form="form"
                  v-if="canEditPaymentIBan"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <employee-swift-code-field
                  id="edit-employee-swift-code"
                  name="swiftCode"
                  :form="form"
                  :required="true"
                  ref="swiftCode"
                  v-if="canEditPaymentSwift"
                />
              </div>
            </div>
          </form>
        </article>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import { SHOW_PAYMENT_EDIT_FORM_EVENT, PROFILE_DATA_UPDATED_EVENT } from "./index";

import { EmployeeIbanField, EmployeeSwiftCodeField } from "./fields";
import { Form, SUC_SELECT_CHANGED_EVENT } from "@/components/form";

import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

import api from "@/api/user";

import { RequiredRule } from "@/components/form/data/rules";

export default {
  components: {
    VueModal,
    EmployeeIbanField,
    EmployeeSwiftCodeField,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      postedNumber: "",
      options: {
        penColor: "#c0f",
      },
      userData: {},
      error: null,
      form: new Form({
        data: {
          key: null,
          iban: null,
          swiftCode: null,
        },
      }),
    };
  },
  computed: {
    ...mapGetters("auth", ["canEditPaymentIBan", "canEditPaymentSwift"]),
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    swiftCodeChangedEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
    fieldsRules() {
      let rules = [];

      if (this.canEditPaymentIBan) {
        rules.push(
          new RequiredRule({
            name: "iban",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.canEditPaymentSwift) {
        rules.push(
          new RequiredRule({
            name: "swiftCode",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      return rules;
    },
  },

  created() {
    EventBus.listen(SHOW_PAYMENT_EDIT_FORM_EVENT, ({ data }) => {
      this.clear();
      this.form.fill(data);
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;
        await this.$refs.swiftCode.init();
        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off([SHOW_PAYMENT_EDIT_FORM_EVENT]);
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);
      if (this.form.validate()) {
        try {
          this.isLoading = true;

          await api.myProfilePaymentUpdate(this.form.data());
          EventBus.fire(PROFILE_DATA_UPDATED_EVENT);

          this.$toasts.success(this.$t("components.messages.success"));

          this.showModal = false;
        } catch (error) {
          Sentry.captureException(error);
          this.error = this.$t("components.messages.error");
          console.error(error);
        }
        this.isLoading = false;
      }
    },
    swiftCodeChanged(code) {
      this.form.swiftCode = code;
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.showModal = false;
    },
    clear() {
      this.error = null;
    },
  },
};
</script>
